import React from 'react';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

export default class SolicitudesAdmin extends React.Component {
    prodNuevo = { Empresa: 2, Codigo: '', Nombre: '', Unidad: '', CodigoSat: '', UnidadSat: '', Precio: 0, TasaIva: 16, TasaIeps: 0, Gravado: true, PedirDescrip: false, NombreUnidad: '', NombreCodigo: '', IvaIncluido : false };
    constructor(props) {
        super(props);
        this.state = {
            editando: false,
            usuario : this.props.u.Id,
            prodActual: this.prodNuevo,
            prioridades:[],
            modulos:[],
            solicitantes:[],
            solicitante:0,
            solicitudes: [],
            solActual:{}
        };
        this.cargarCatalogos();
    }
    componentDidMount = () => {
        this.cargarSolicitudes();
    }
    onChange = (e) => {
        const campo = e.target.name;
        this.setState({[campo]: e.target.value},()=>{
            if(campo === "solicitante")
                this.cargarSolicitudes();
        });
    }  
    cargarCatalogos = async () => {
        let url = `${this.props.urlws}/solicitud/listaPrioridades`;
        let res = await fetch(url);
        const prioridades = await res.json();
        url = `${this.props.urlws}/solicitud/listaModulos`;
        res = await fetch(url);
        const modulos = await res.json();
        url = `${this.props.urlws}/solicitud/solicitantes`;
        res = await fetch(url);
        const solicitantes = await res.json();
        this.setState({ prioridades,modulos,solicitantes });
    }
    cargarSolicitudes = async () => {
        const url = this.props.urlws + "/solicitud/lista/?emp=" + this.state.solicitante+"&fecIni=2020-01-01&fecFin=2020-12-31";
        const res = await fetch(url);
        const solicitudes = await res.json();
        this.setState({ solicitudes });
    }
    cargarSol = async (cod) => {
        const url = `${this.props.urlws}/solicitud/wscargar?num=${cod}&emp=${this.state.solicitante}`;
        const res = await fetch(url);
        const solActual = await res.json();
        this.setState({ editando: true, solActual });
    }
    onClickNuevo = () =>{
        this.cargarSol(0);
    }
    editar = (cod) => {
        this.cargarSol(cod);
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(datos) => {
        const url = `${this.props.urlws}/solicitud/wsasignarFecha?emp=${this.state.solicitante}&num=${datos.numero}&fec=${datos.fechaProp}&usu=${this.state.usuario}&comenta=${datos.comenta}`;
        const req = await fetch(url);
        const res = await req.text();
        this.cargarSolicitudes();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarSolicitudes());
        Swal.fire('Atencion','Datos guardados.','success');
    }
    agregarComentario = async(datos, comenta) => {
        const url = `${this.props.urlws}/solicitud/wsguardarComenta?emp=${this.state.solicitante}&num=${datos.numero}&usu=${this.state.usuario}&s=${comenta}`;
        const req = await fetch(url);
        const res = await req.text();
        this.cargarSolicitudes();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false, verDetalle:false},()=> this.cargarSolicitudes());
        Swal.fire('Atencion','Datos guardados.','success');
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-3">
                      <Form.Group>
                          <Form.Label>Cliente</Form.Label>
                          <Form.Control size="sm" as="select" name="solicitante" value={this.state.solicitante} onChange={this.onChange}>
                              <option key="0" value={0}>Especifique</option>
                              {this.state.solicitantes.map((i, k) =>
                                  <option key={i.Id} value={i.Id}>{i.Nombre}</option>
                              )}
                          </Form.Control>
                      </Form.Group>
                  </div>
                  <div className="col">
                    <h2 className="text-center">Solicitudes</h2>
                  </div>
                </div>                
                {this.state.editando ?
                    <Formulario solicitud={this.state.solActual} modulos={this.state.modulos} prioridades={this.state.prioridades} guardar={this.guardar} agregar={this.agregarComentario} cancelar={this.cancelar} />
                    :
                    <Lista solicitudes={this.state.solicitudes} editar={this.editar} />
                }
            </div>
        )
    }
}

function Lista({ solicitudes, editar}) {
    return (
        <ul>
            {solicitudes.map((item, index) =>
                <Solicitud key={index} solicitud={item} editar={editar}/>
            )}
        </ul>
    );
}
function Solicitud({ solicitud, editar }) {
    return (
        <li className="list-group-item" >
            <p>
                Solicitud:<span className="font-weight-bold">{solicitud.Numero}</span>
                {' '}
                <b>{solicitud.Nombre}</b>
            </p>
            <p>
                Modulo:
                {' '}
                <b>{solicitud.Modulo}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;
                Prioridad:
                {' '}
                <b>{solicitud.Prioridad}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;
                Status:
                {' '}
                <b>{solicitud.NomStatus}</b>
                {solicitud.Status > 1?
                    <span>
                        {' '}
                        Fecha propuesta de realizacion:
                        {' '}
                        <b>{solicitud.FechaProp}</b>
                    </span>
                :
                null}
            </p>
            <p>
                Descripcion:
                {' '}
                <span>{solicitud.Descripcion}</span>
            </p>
            <div>
                <button className="btn btn-sm btn-success" onClick={() => editar(solicitud.Numero)}>Editar</button>
            </div>
        </li>
    )
}

class Formulario extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        numero : props.solicitud.Numero,
        nombre:props.solicitud.Nombre,
        descripcion:props.solicitud.Descripcion,
        modulo:props.solicitud.Modulo,
        modulos:props.solicitud.Modulos,
        prioridad:props.solicitud.Prioridad,
        prioridades:props.solicitud.Prioridades,
        fechaProp:props.solicitud.FechaProp,
        comentarios:props.solicitud.Comentarios,
        comenta:''
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            nombre:newProps.solicitud.Nombre,
            descripcion:newProps.solicitud.Descripcion,
            modulo:newProps.solicitud.Modulo,
            prioridades:newProps.solicitud.Prioridad,
        });
    }
    onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
    }
    onSubmit = (e) => {
        e.preventDefault();
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    agregar = async(e) => {
        e.preventDefault();
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputPlaceholder: 'escriba su comentario',
            inputAttributes: {
              'aria-label': 'escriba su comentario'
            },
            showCancelButton: true
          });  
          if (text) {
            this.props.agregar(this.state,text);
        }        
    }
    render () {
      return(
          <div>
          <form onSubmit={this.onSubmit}>
              <div className="row">
                  <div className="col">
                      <div className="form-group" size="sm">
                          <label>Solicitud</label>
                          <Form.Control size="sm" type="text" name="nombre" value={this.state.nombre} />
                      </div>
                  </div>
                  <div className="col-2">
                      <Form.Group>
                          <Form.Label>Modulo</Form.Label>
                          <Form.Control size="sm" as="select" name="modulo" value={this.state.modulo}>
                              <option key="0" value={0}>Especifique</option>
                              {this.props.modulos.map((i, k) =>
                                  <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                              )}
                          </Form.Control>
                      </Form.Group>
                  </div>
                  <div className="col-2">
                      <Form.Group>
                          <Form.Label>Prioridad</Form.Label>
                          <Form.Control size="sm" as="select" name="prioridad" value={this.state.prioridad}>
                              <option key="0" value={0}>Especifique</option>
                              {this.props.prioridades.map((i, k) =>
                                  <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                              )}
                          </Form.Control>
                      </Form.Group>
                  </div>
              </div>
              <div className="row">
                  <div className="col">
                      <div className="form-group" size="sm">
                          <label>Descripcion</label>
                          <textarea className="form-control" rows="5" name="descripcion" value={this.state.descripcion} ></textarea>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-2">
                      <div className="form-group" size="sm">
                          <label>Fecha probable</label>
                          <Form.Control size="sm" type="date" name="fechaProp" value={this.state.fechaProp} onChange={this.onChange} />
                      </div>
                  </div>
                  <div className="col">
                      <div className="form-group" size="sm">
                          <label>Comentario</label>
                          <Form.Control size="sm" type="text" name="comenta" value={this.state.comenta} onChange={this.onChange} />
                      </div>
                  </div>
              </div>
              <input size="sm" type="submit" className="btn btn-sm btn-success" value="Guardar" />
                {' '}
            <div className="row">
                <div className="col">
                    {this.state.comentarios.map((i, k) =>
                        <Comentario key={i.R} fecha={i.F} comenta={i.C} usuario={i.U} />
                    )}
                  </div>
              </div>
          </form>
            <button size="sm" className="btn btn-sm btn-success" onClick={this.agregar}>Agregar comentario</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button size="sm" className="btn btn-sm btn-danger" onClick={this.props.cancelar}>Cancelar</button>
        </div>
      )
    }
  }
  class Comentario extends React.Component{
    render(){
        return(
            <p>
                <span>Fecha : <b>{this.props.fecha}</b> - <b>{this.props.usuario}</b></span><br/>
                <span>{this.props.comenta}</span><br/>
                <hr/>
            </p>
        )
    }
}
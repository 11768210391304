import React from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Solicitudes from './componentes/Solicitudes';
import SolicitudesAdmin from './componentes/SolicitudesAdmin';
import Header from './Header';
import Login from './componentes/Login';

export default class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      urlws:'https://www.facturar.isaerpweb.com',
      urlwsx:'http://localhost:29674',
      lg:false,
      emp:0,
      u:{}
    }
  }
  componentDidMount = () => {
    localStorage.removeItem("facturas");
    localStorage.removeItem("captura");
    localStorage.removeItem("u");
  }
  iniciar = (usu) =>{
    this.setState({
      lg:true, emp : usu.Empresa, u : usu
    });
    const u = JSON.stringify(usu);
    localStorage.setItem("u", u);
  }
  render(){
    const estilo = {visibility : this.state.lg?'visible':'hidden'};
    console.log(this.state.u.Empresa);
    return (
    <Router>
      { this.state.lg ? <Header u={this.state.u}/> : <Login iniciar={this.iniciar} urlws={this.state.urlws}/>}
      <div style={estilo}>
      { this.state.lg && this.state.u.Id != 3?
        <Solicitudes {...this.props} u={this.state.u} urlws={this.state.urlws} />
        : null 
        }
      { this.state.lg && this.state.u.Id == 3?
        <SolicitudesAdmin {...this.props} u={this.state.u} urlws={this.state.urlws} />
        : null 
        }
      </div>
    </Router>
  );
  }
}
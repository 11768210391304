import React from 'react';
import {NavLink} from 'react-router-dom';
function Header(props){
    return(
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <span className="navbar-brand">Gestor de solicitudes de servicio</span>
                <ul className="navbar-nav mr-auto">
                </ul>
                <span style={{color:'white'}} size="sm">{props.u.Nombre}</span>
            </div>
        </nav>
    );
}

export default Header;